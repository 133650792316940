<template>
  <div class="plt">
    <header class="mb20">
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="borgray bor-rad plt">
            <p class="c6 mt10">租电总收入（元）</p>
            <p class="mt10">{{listObj.leaseTotalIncome | handleNull}}</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="borgray bor-rad plt">
            <p class="c6 mt10">今日新增租电收益（元）</p>
            <p class="mt10">{{listObj.leaseTodayIncome | handleNull}}</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="borgray bor-rad plt">
            <p class="c6 mt10">本月新增租电收益（元）</p>
            <p class="mt10">{{listObj.leaseMonthIncome | handleNull}}</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="borgray bor-rad plt">
            <p class="c6 mt10">今日租电订单数量</p>
            <p class="mt10">{{listObj.leaseOrderNum | handleNull}}</p>
          </div>
        </el-col>
      </el-row>
    </header>
    <section class="section-wrapper">
      <lttEcharts :option="option" refLabel="incomeChart"/>
    </section>
    <!-- table -->
    <footer class="borgray bor-rad mb30" >
      <el-table :data="tableData" style="width: 100%" > 
        <el-table-column prop="number" label="订单号" width="170"/>
        <el-table-column prop="mainNumber" label="父单号" width="170"/>
        <el-table-column prop="deviceNo" label="通讯板号" width="170"/> 
        <el-table-column prop="unitName" label="代理商"/> 
        <el-table-column prop="storeName" label="门店"/>
        <el-table-column prop="orderMoney" label="订单金额(不含保险)"/> 
        <el-table-column prop="myIncome" label="我的收益"/> 
        <el-table-column prop="unitIncome" label="代理商收益"/> 
        <el-table-column prop="storeIncome" label="门店收益"/> 
        <el-table-column prop="createTime" label="创建时间"> 
          <template slot-scope="scope">
            {{scope.row.createTime | timeToDate}}
          </template>
        </el-table-column>
      </el-table>
    </footer>
    <div class="dfc fe">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="beginIndex"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import lttEcharts from "@/components/lttEcharts";
import { getIncomeStatistics } from "@/api/dataStatement";
export default {
  data() {
    return {
      total:0,
      beginIndex:1,
      pageSize:50,
      listObj:{},//租电总收入
      tableData:[],
      option: {
        title: {
           text: '统计',
           textStyle: {
            fontSize: 14,
            color:'gray'
          },
        },
      tooltip: {
        trigger: 'axis',
      },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: [
          {
            name: "金额",
            position: "right",
            // type: 'value'
          },
        ],
        series: [
          {
            data: [],
            type: "line",
            areaStyle: {},
          },
        ],
      },
      
    };
  },
  mounted() {
    this.getIncomeStatistics();
  },
  methods: {
    handleCurrentChange(page){
      this.beginIndex = page;
      this.getIncomeStatistics();
    },
    async getIncomeStatistics(){
      const params = {
        beginIndex:this.beginIndex - 1,
        pageSize:this.pageSize,
      }
      try{
        const res = await getIncomeStatistics(params);
        this.total = res.mdata.total;
        // 租电总输入数据
        this.listObj = res.mdata.leaseIncomeStatisticsDto;
        // 表格
        this.tableData = res.mdata.leaseIncomeStatisticsDto.leaseIncomeListDtoList;
        // echarts数据
        let chartsData = res.mdata.leaseIncomeStatisticsDto.leaseTodayIncomeDtos;
        let xAxis = [];
        let series = [];
        chartsData.forEach(el => {
          xAxis.push(el.day);
          series.push(el.leaseIncome);
        });
        this.option.xAxis.data = xAxis;
        this.option.series[0].data = series;
      }catch(e){
        console.log(e,'e');
      }

      
    }
  },
  components: {
    lttEcharts,
  },
};
</script>

<style scoped>
/* #myCharts{
   width: 100%;
   height: 300px;
   border: 1px solid black;
 } */
 footer{
   color: #fff;
   padding: 10px;
 }
</style>
